import UAParser from 'ua-parser-js';

import {
  isNumeric,
  isString,
  toArray, useMediaContext
} from '#widgets';

const agent = new UAParser();

const getGreaterBreakpoint = (breakpoints, min) => Object.keys(breakpoints)
  .reduce((accumulator, key) => {
    const value = breakpoints[key];
    if (value > min) {
      if (!accumulator || accumulator > value) {
        return value;
      }
    }
    return accumulator;
  }, null);

const getExactBreakpoint = (breakpoints, value) => {
  if (isNumeric(value)) {
    return Number(value);
  }
  if (isString(value)) {
    const breakpoint_value = breakpoints[value];
    if (isNumeric(breakpoint_value)) {
      return Number(breakpoint_value);
    }
  }
  return null;
};

export const checkViewport = (width, only, from, to) => {
  if (only || from || to) {
    const breakpoints = {
      lg: 992,
      md: 768,
      sm: 576,
      xl: 1200,
      xs: 0,
      xxl: 1600
    };
    const min = getExactBreakpoint(breakpoints, only || from);
    const max = only ? getGreaterBreakpoint(breakpoints, min) : getExactBreakpoint(breakpoints, to);
    return (min === null || min <= width) && (max === null || max > width);
  }
  return true;
};

export const checkDevice = (devices) => {
  const array_devices = toArray(devices);
  if (array_devices.length) {
    return array_devices.includes(agent.getDevice().type);
  }
  return true;
};
