/* eslint-disable import/prefer-default-export */
import { HTMLAttributes } from 'react';

import { classNames } from '#widgets';

export const makeContainer = ({
  className: class_name_prop,
  ...props
}: HTMLAttributes<HTMLAllCollection>) => ({
  ...props,
  className: classNames('wat-container', class_name_prop)
});
