export const wrap_values = [
  'wrap',
  'nowrap',
  'unset'
];

export const direction_values = [
  'row',
  'column',
  'row-reverse',
  'column-reverse',
  'unset'
];

export const justify_values = [
  'start',
  'end',
  'center',
  'around',
  'between',
  'unset'
];

export const items_values = [
  'start',
  'end',
  'center',
  'stretch',
  'baseline',
  'unset'
];

export const content_values = [
  'start',
  'end',
  'center',
  'stretch',
  'around',
  'between',
  'unset'
];

export const self_values = [
  'start',
  'end',
  'center',
  'stretch',
  'baseline',
  'unset'
];

export const style_keys = [
  'display',
  'displayXs',
  'displaySm',
  'displayMd',
  'displayXl',
  'displayXxl',
  'show',
  'showXs',
  'showSm',
  'showMd',
  'showXl',
  'showXxl',
  'hide',
  'hideXs',
  'hideSm',
  'hideMd',
  'hideXl',
  'hideXxl',
  'inline',
  'inlineXs',
  'inlineSm',
  'inlineMd',
  'inlineLg',
  'inlineXl',
  'inlineXxl',
  'wrap',
  'wrapXs',
  'wrapSm',
  'wrapMd',
  'wrapLg',
  'wrapXl',
  'wrapXxl',
  'direction',
  'directionXs',
  'directionSm',
  'directionMd',
  'directionLg',
  'directionXl',
  'directionXxl',
  'justify',
  'justifyXs',
  'justifySm',
  'justifyMd',
  'justifyLg',
  'justifyXl',
  'justifyXxl',
  'items',
  'itemsXs',
  'itemsSm',
  'itemsMd',
  'itemsLg',
  'itemsXl',
  'itemsXxl',
  'content',
  'contentXs',
  'contentSm',
  'contentMd',
  'contentLg',
  'contentXl',
  'contentXxl',
  'self',
  'selfXs',
  'selfSm',
  'selfMd',
  'selfLg',
  'selfXl',
  'selfXxl',
  'grow',
  'growXs',
  'growSm',
  'growMd',
  'growLg',
  'growXl',
  'growXxl',
  'shrink',
  'shrinkXs',
  'shrinkSm',
  'shrinkMd',
  'shrinkLg',
  'shrinkXl',
  'shrinkXxl',
  'order',
  'orderXs',
  'orderSm',
  'orderMd',
  'orderLg',
  'orderXl',
  'orderXxl',
  'flex',
  'flexXs',
  'flexSm',
  'flexMd',
  'flexLg',
  'flexXl',
  'flexXxl',
  'basis',
  'basisXs',
  'basisSm',
  'basisMd',
  'basisLg',
  'basisXl',
  'basisXxl',
  'show',
  'showXs',
  'showSm',
  'showMd',
  'showLg',
  'showXl',
  'showXxl',
  'hide',
  'hideXs',
  'hideSm',
  'hideMd',
  'hideLg',
  'hideXl',
  'hideXxl'
];
