/* eslint-disable jsx-a11y/alt-text */
import NextImage from 'next/image';
import PropTypes from 'prop-types';
import React from 'react';

const Image = ({
  webp, ...props
}) => {
  const svgRg = new RegExp(/.*\.svg$/);

  if (svgRg.test(props.src)) {
    return <img {...props} />;
  }

  if (props.src) {
    return (
      <NextImage
        {...(props.width ? {} : { layout: 'fill' })}
        {...props}
      />
    );
  }

  return null;
};

Image.propTypes = {
  alt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  src: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]).isRequired,
  webp: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ])
};

Image.defaultProps = {
  alt: 'win a talent',
  webp: null
};

export default Image;
