import { kebabCase  } from 'change-case';

import { classNames } from '#widgets';

import { style_keys } from './constant';

const prefix = 'wat';

const fixClassNames = (cell, class_names) => {
  const class_names_string = classNames(class_names);
  return cell ? class_names_string.split('show').join('cell') : class_names_string;
};

const splitClassNamesFromProps = (props) => {
  const other_props = {};
  const class_names = [`${prefix}-show`];
  Object.keys(props).forEach((item) => {
    const value = props[item];
    if (style_keys.includes(item)) {
      if (value || value === 0) {
        const label = [prefix, kebabCase(item), value].join('-').replace('-true', '');
        class_names.push(label);
      }
    } else {
      other_props[item] = value;
    }
  });
  return {
    class_names,
    other_props
  };
};

export const makeFlex = ({ className: class_name_prop, ...props }, cell = false) => {
  const {
    class_names,
    other_props
  } = splitClassNamesFromProps(props);
  return {
    ...other_props,
    className: classNames(fixClassNames(cell, class_names), class_name_prop)
  };
};
