/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React, { ElementType, FC, HTMLAttributes } from 'react';

import { cn } from '#tools';

interface ComponentProps extends HTMLAttributes<HTMLOrSVGElement> {
  as?: ElementType;
  highlightColor?: string;
  height?: string;
  width?: number|'full';
  bgColor?:string
}

const Highlight: FC<ComponentProps> = ({
  as: Tag = 'span',
  height = 'h-[10px]',
  width = 20,
  className,
  bgColor = 'bg-[#B29CBF]',
  ...otherProps
}) => (
  <Tag className={cn('relative z-10 ', className)} {...otherProps}>
    <span
      className={cn(
        '   absolute top-1/2   -translate-y-1/2 -z-10  ',
        height,
        bgColor
      )}
      style={{
        width: typeof width === 'number' ? `${width}px` : '100%'

      }}
    />
    {otherProps.children}
  </Tag>
);

export { Highlight };
