import PropTypes from 'prop-types';
import React from 'react';

import { useTextContext } from './context';

const Text = ({ id, values }) => {
  const { text } = useTextContext();

  return text(id, values);
};

Text.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object
};

Text.defaultProps = {
  values: null
};

export default Text;
