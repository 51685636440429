import React, { ReactNode } from 'react';
import { run } from '#widgets';
import { when } from './utility';

interface WhenProps {
  condition?: unknown;
  render?: () => ReactNode;
  children?: ReactNode;
  unless?: boolean;
}

const When: React.FC<WhenProps> = ({
  condition,
  render,
  children,
  unless = false,
}) => {
  if (when(condition, unless)) {
    return <>{children || (render && run(render))}</>;
  }
  return null;
};

export default When;
