import PropTypes from 'prop-types';
import React, { memo } from 'react';

import {
  content_values,
  direction_values,
  items_values,
  justify_values,
  self_values,
  wrap_values
} from './constant';
import { makeFlex } from './utility';

const Flex = ({
  tag: Tag, cell, children, ...props
}) => (
  <Tag {...makeFlex(props, cell)}>
    {children}
  </Tag>
);

const prop_types = {
  bool: PropTypes.bool,
  content: PropTypes.oneOf(content_values),
  direction: PropTypes.oneOf(direction_values),
  items: PropTypes.oneOf(items_values),
  justify: PropTypes.oneOf(justify_values),
  node: PropTypes.node,
  number: PropTypes.number,
  self: PropTypes.oneOf(self_values),
  string: PropTypes.string,
  wrap: PropTypes.oneOf(wrap_values)
};

Flex.propTypes = {
  basis: prop_types.number,
  basisLg: prop_types.number,
  basisMd: prop_types.number,
  basisSm: prop_types.number,
  basisXl: prop_types.number,
  basisXs: prop_types.number,
  basisXxl: prop_types.number,
  cell: prop_types.bool,
  children: prop_types.node,
  content: prop_types.content,
  contentLg: prop_types.content,
  contentMd: prop_types.content,
  contentSm: prop_types.content,
  contentXl: prop_types.content,
  contentXs: prop_types.content,
  contentXxl: prop_types.content,
  direction: prop_types.direction,
  directionLg: prop_types.direction,
  directionMd: prop_types.direction,
  directionSm: prop_types.direction,
  directionXl: prop_types.direction,
  directionXs: prop_types.direction,
  directionXxl: prop_types.direction,
  flex: prop_types.number,
  flexMd: prop_types.number,
  flexSm: prop_types.number,
  flexXl: prop_types.number,
  flexXs: prop_types.number,
  flexXxl: prop_types.number,
  grow: prop_types.number,
  growLg: prop_types.number,
  growMd: prop_types.number,
  growSm: prop_types.number,
  growXl: prop_types.number,
  growXs: prop_types.number,
  growXxl: prop_types.number,
  hide: prop_types.bool,
  hideLg: prop_types.bool,
  hideMd: prop_types.bool,
  hideSm: prop_types.bool,
  hideXl: prop_types.bool,
  hideXs: prop_types.bool,
  hideXxl: prop_types.bool,
  items: prop_types.items,
  itemsLg: prop_types.items,
  itemsMd: prop_types.items,
  itemsSm: prop_types.items,
  itemsXl: prop_types.items,
  itemsXs: prop_types.items,
  itemsXxl: prop_types.items,
  justify: prop_types.justify,
  justifyLg: prop_types.justify,
  justifyMd: prop_types.justify,
  justifySm: prop_types.justify,
  justifyXl: prop_types.justify,
  justifyXs: prop_types.justify,
  justifyXxl: prop_types.justify,
  order: prop_types.number,
  orderLg: prop_types.number,
  orderMd: prop_types.number,
  orderSm: prop_types.number,
  orderXl: prop_types.number,
  orderXs: prop_types.number,
  orderXxl: prop_types.number,
  self: prop_types.self,
  selfLg: prop_types.self,
  selfMd: prop_types.self,
  selfSm: prop_types.self,
  selfXl: prop_types.self,
  selfXs: prop_types.self,
  selfXxl: prop_types.self,
  show: prop_types.bool,
  showLg: prop_types.bool,
  showMd: prop_types.bool,
  showSm: prop_types.bool,
  showXl: prop_types.bool,
  showXs: prop_types.bool,
  showXxl: prop_types.bool,
  shrink: prop_types.number,
  shrinkLg: prop_types.number,
  shrinkMd: prop_types.number,
  shrinkSm: prop_types.number,
  shrinkXl: prop_types.number,
  shrinkXs: prop_types.number,
  shrinkXxl: prop_types.number,
  tag: prop_types.string,
  wrap: prop_types.wrap,
  wrapLg: prop_types.wrap,
  wrapMd: prop_types.wrap,
  wrapSm: prop_types.wrap,
  wrapXl: prop_types.wrap,
  wrapXs: prop_types.wrap,
  wrapXxl: prop_types.wrap
};

Flex.defaultProps = {
  basis: null,
  basisLg: null,
  basisMd: null,
  basisSm: null,
  basisXl: null,
  basisXs: null,
  basisXxl: null,
  cell: false,
  children: null,
  content: null,
  contentLg: null,
  contentMd: null,
  contentSm: null,
  contentXl: null,
  contentXs: null,
  contentXxl: null,
  direction: null,
  directionLg: null,
  directionMd: null,
  directionSm: null,
  directionXl: null,
  directionXs: null,
  directionXxl: null,
  flex: null,
  flexMd: null,
  flexSm: null,
  flexXl: null,
  flexXs: null,
  flexXxl: null,
  grow: null,
  growLg: null,
  growMd: null,
  growSm: null,
  growXl: null,
  growXs: null,
  growXxl: null,
  hide: false,
  hideLg: false,
  hideMd: false,
  hideSm: false,
  hideXl: false,
  hideXs: false,
  hideXxl: false,
  items: null,
  itemsLg: null,
  itemsMd: null,
  itemsSm: null,
  itemsXl: null,
  itemsXs: null,
  itemsXxl: null,
  justify: null,
  justifyLg: null,
  justifyMd: null,
  justifySm: null,
  justifyXl: null,
  justifyXs: null,
  justifyXxl: null,
  order: null,
  orderLg: null,
  orderMd: null,
  orderSm: null,
  orderXl: null,
  orderXs: null,
  orderXxl: null,
  self: null,
  selfLg: null,
  selfMd: null,
  selfSm: null,
  selfXl: null,
  selfXs: null,
  selfXxl: null,
  show: false,
  showLg: false,
  showMd: false,
  showSm: false,
  showXl: false,
  showXs: false,
  showXxl: false,
  shrink: null,
  shrinkLg: null,
  shrinkMd: null,
  shrinkSm: null,
  shrinkXl: null,
  shrinkXs: null,
  shrinkXxl: null,
  tag: 'div',
  wrap: null,
  wrapLg: null,
  wrapMd: null,
  wrapSm: null,
  wrapXl: null,
  wrapXs: null,
  wrapXxl: null
};

export default memo(Flex);
