import React, {
  ElementType, FC, HTMLAttributes, memo
} from 'react';

import { makeContainer } from './utility';

interface ComponentProps extends HTMLAttributes<HTMLAllCollection> {
  tag?: ElementType;
}

const Container: FC<ComponentProps> = ({
  tag: Tag = 'div',
  children,
  ...props
}) => <Tag {...makeContainer(props)}>{children}</Tag>;

export default memo(Container);
