import PropTypes from 'prop-types';
import React, { useState } from 'react';

import {
  isValued,
  useEvent
} from '#widgets';

import { Choose, When } from '../control';
import {
  checkDevice,
  checkViewport
} from './utility';

/* TODO-qp:: bind Media component with user agent parser */
const Media = ({
  only, to, from, device, children, render, fallback
}) => {
  if (typeof window !== 'undefined') {
    const [width, setWidth] = useState(window.innerWidth);
    useEvent('resize', () => setWidth(window.innerWidth));
    const valid_device = checkDevice(device);
    const valid_viewport = checkViewport(width, only, from, to);
    return (
      <Choose>
        <When condition={valid_device && valid_viewport}>
          {children || render()}
        </When>
        <When unless>
          {fallback({ device: valid_device, viewport: valid_viewport })}
        </When>
      </Choose>
    );
  }
  return null;
};

Media.propTypes = {
  children: PropTypes.node,
  device: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  fallback: PropTypes.func,
  from: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  only: PropTypes.string,
  render: PropTypes.func,
  to: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
};

Media.defaultProps = {
  children: null,
  fallback: () => null,
  from: null,
  only: null,
  render: () => null,
  to: null
};

export default Media;
